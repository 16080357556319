import React from 'react';

import { MDXRendererWithMapping } from '../../Common/MarkdownRenderers';
import TableOfContents from '../../Common/TableOfContents';
import Content from '../../Content/Content';

const MigrationTab = props => {
  const { migrationGuide } = props;
  return (
    <Content
      accessory={
        migrationGuide.tableOfContents.items ? (
          <div style={{ position: 'sticky', top: '0' }}>
            <TableOfContents items={migrationGuide.tableOfContents.items} />
          </div>
        ) : undefined
      }
    >
      <MDXRendererWithMapping md={migrationGuide.body} />
    </Content>
  );
};

export default MigrationTab;
