import React, { useContext } from 'react';
import * as Ink from '@carta/ink';
import path from 'path';
import { graphql, withPrefix } from 'gatsby';
import { Router, Redirect } from '@reach/router';
import { useAnalyticsV2 } from '@carta/fep-analytics';
import humanize from 'humanize-react';
import Header from '../Header/Header';
import SamplesTab from './Tabs/SamplesTab';
import PropsTab from './Tabs/PropsTab';
import UsageTab from './Tabs/UsageTab';
import MigrationTab from './Tabs/MigrationTab';
import TestingTab from './Tabs/TestingTab';
import AccessibilityTab from './Tabs/AccessibilityTab';
import ComponentNavigation from './ComponentNavigation';
import Layout from '../Layout';
import Content from '../Content/Content';
import DocsContext from '../../contexts/DocsContext';
import StatusBubble from '../Common/StatusBubble';
import StyledLink from '../Common/StyledLink';
import pj from '../../../package.json';

const LinksToDeprecated = ({
  replacementComponent,
  migrateAway,
}: {
  replacementComponent: string;
  migrateAway?: boolean;
}) => {
  const { trackAction } = useAnalyticsV2();
  const names = replacementComponent.split(',').map(name => name.trim());

  const links = names.map(name => (
    <StyledLink
      onClick={() => {
        trackAction({
          elementId: `deprecated-link-${name}`,
          type: 'click',
        });
      }}
      to={`/components/${name}${migrateAway ? '/migration-guide' : '/'}`}
    >
      {name}
    </StyledLink>
  ));

  return humanize(links, { oxfordComma: true, conjunction: 'or' });
};

const StaffBanner = ({ name }) => {
  const { trackAction } = useAnalyticsV2();
  return (
    <Ink.Banner
      type="staff"
      title={`${name} is staff-only`}
      text={
        <>
          You should never let {name} be visible to end users.{' '}
          <Ink.Anchor
            onClick={() => trackAction({ elementId: `staff-banner-link-${name}`, type: 'click' })}
            href={`/components/${name}/usage`}
            preset="forward"
          >
            See the details
          </Ink.Anchor>
        </>
      }
    />
  );
};

const AlternateImplementationBanner = ({ component }) => {
  const { trackAction } = useAnalyticsV2();

  return (
    <Ink.Banner
      type="info"
      title={`${component.displayName} has an alternative implementation`}
      text={
        <>
          Click{' '}
          <StyledLink
            onClick={() => {
              trackAction({
                elementId: `alternate-implementation-banner-link-${component.displayName}`,
                type: 'click',
              });
            }}
            to={component.alternatePatternLink}
          >
            here
          </StyledLink>{' '}
          to explore another way to apply this pattern.
        </>
      }
    />
  );
};

const StatusBanner = ({ component }) => {
  const { trackAction } = useAnalyticsV2();

  switch (component.status) {
    case 'sunset':
      if (component.replacementComponent) {
        return (
          <Ink.Banner
            type="warning"
            title={`${component.displayName} has a new version`}
            text={
              <>
                Use <LinksToDeprecated replacementComponent={component.replacementComponent} /> instead. This component
                will be deprecated {component.dateOfDeletion ? `on ${component.dateOfDeletion}` : 'soon'}.
              </>
            }
          />
        );
      }
      if (component.alternatePatternLink) {
        return (
          <Ink.Banner
            type="warning"
            title={`${component.displayName} is being phased out ${
              component.dateOfDeletion ? `on ${component.dateOfDeletion}` : 'soon'
            }`}
            text={
              <>
                <StyledLink
                  onClick={() => {
                    trackAction({
                      elementId: `sunset-banner-link-${component.displayName}`,
                      type: 'click',
                    });
                  }}
                  to={component.alternatePatternLink}
                >
                  See an alternative implementation
                </StyledLink>
                .
              </>
            }
          />
        );
      }
      return (
        <Ink.Banner
          type="warning"
          title={`${component.displayName} is being phased out ${
            component.dateOfDeletion ? `on ${component.dateOfDeletion}` : 'soon.'
          }`}
        />
      );
    case 'deprecated':
      if (component.replacementComponent) {
        return (
          <Ink.Banner
            type="error"
            title={`${component.displayName} has a new version`}
            text={
              <>
                {component.displayName} is scheduled for deletion{' '}
                {component.dateOfDeletion ? `on ${component.dateOfDeletion}` : 'soon'}, pending the migration of
                remaining instances. Use <LinksToDeprecated replacementComponent={component.replacementComponent} />{' '}
                instead.
              </>
            }
          />
        );
      }
      if (component.alternatePatternLink) {
        return (
          <Ink.Banner
            type="error"
            title={`${component.displayName} has a new pattern`}
            text={
              <>
                {component.displayName} is scheduled for deletion{' '}
                {component.dateOfDeletion ? `on ${component.dateOfDeletion}` : 'soon'}, pending the migration of
                remaining instances.{' '}
                <StyledLink to={component.alternatePatternLink}>See an alternative implementation</StyledLink>.
              </>
            }
          />
        );
      }
      break;
    default:
      return null;
  }
  return null;
};

const Component = ({
  data: {
    cmp,
    usageGuide,
    relatedComponentGuides,
    relatedHooks,
    relatedPatterns,
    relatedComponents,
    migrateToGuide,
    migrateAwayGuide,
  },
  location,
}) => {
  const { defaultComponentTab } = useContext(DocsContext);
  const tabs = ['examples', 'props', 'usage', 'testing', 'accessibility'];

  const witheringComponent = cmp.status && (cmp.status === 'deprecated' || cmp.status === 'sunset');
  const hasAlternateImplementation = cmp.alternatePatternLink && !(cmp.status === 'deprecated' || cmp.deprecated);
  const hasProps = cmp.props.length > 0;
  const staffOnly = cmp.staffonly === 'true' && !(cmp.status === 'deprecated' || cmp.deprecated);

  if (migrateToGuide) tabs.push('migration-guide');

  const tab = tabs.includes(path.basename(location.pathname)) ? path.basename(location.pathname) : defaultComponentTab;

  /*
    If the user's default is `explorer` we'll default them to the first tab instead 
    so they don't end up with an error since we deleted the explorer tab.
  */
  const userPrefFallback =
    defaultComponentTab === 'explorer' && (witheringComponent || !hasProps) ? tabs[0] : defaultComponentTab;

  return (
    <Layout pageTitle={cmp.displayName}>
      <Ink.Box bottom="medium">
        <StyledLink to="/components">Components</StyledLink>
      </Ink.Box>
      <Header
        title={cmp.displayName}
        bubble={<StatusBubble status={cmp.status} />}
        subtitle={!witheringComponent ? cmp.purpose : ''}
        actions={[
          {
            label: 'See usage',
            type: 'btn',
            href: `/usage/components/${cmp.displayName}`,
          },
          {
            label: 'View source',
            type: 'btn',
            href: `https://github.com/carta/ink/blob/${pj.dependencies['@carta/ink']}/${cmp.relativePath}`,
          },
          {
            label: 'Contribute',
            type: 'primary',
            href: 'https://github.com/carta/ink/blob/master/CONTRIBUTING.md',
          },
        ]}
      />
      {witheringComponent && <StatusBanner component={cmp} migrateAway={migrateAwayGuide} />}
      {hasAlternateImplementation && <AlternateImplementationBanner component={cmp} />}
      {staffOnly && <StaffBanner name={cmp.displayName} />}
      <Ink.Box top="medium">
        <ComponentNavigation activeNavItem={tab} displayName={cmp.displayName} items={tabs} />
      </Ink.Box>
      <Content>
        <Router basepath={withPrefix(`/components/${cmp.displayName}/`)}>
          <Redirect noThrow from="/" to={`./${userPrefFallback}`} />
          <SamplesTab component={cmp} path="/examples" />
          <PropsTab component={cmp} relatedComponents={relatedComponents} path="/props" />
          <UsageTab
            component={cmp}
            usageGuide={usageGuide}
            relatedPatterns={relatedPatterns}
            relatedComponentGuides={relatedComponentGuides}
            relatedHooks={relatedHooks}
            path="/usage"
          />
          <TestingTab component={cmp} path="/testing" />
          <AccessibilityTab component={cmp} path="/accessibility" />
          <MigrationTab component={cmp} migrationGuide={migrateToGuide} path="/migration-guide" />
        </Router>
      </Content>
    </Layout>
  );
};

export default Component;
export const query = graphql`
  query Page($slug: String!, $group: String) {
    cmp: componentsJson(displayName: { eq: $slug }) {
      compiledMd
      accessibilityMd
      testingMd
      displayName
      HTMLType
      position
      purpose
      relativePath
      replacementComponent
      alternatePatternLink
      dateOfDeletion
      status
      staffonly
      props {
        description
        name
        required
        deprecatedProp
        replacementProp
        declarations {
          fileName
          name
        }
        defaultValue {
          value
        }
        parent {
          fileName
          name
        }
        type {
          name
          definition {
            fileName
            start
            end
          }
          raw
          value {
            value
          }
        }
      }
      samples {
        code
        description
        environments
        name
        solidBackground
      }
    }
    usageGuide: mdx(
      fileAbsolutePath: { regex: "/usage-guides/" }
      frontmatter: { componentUsageGuide: { in: [$slug] } }
    ) {
      body
    }
    migrateToGuide: mdx(
      fileAbsolutePath: { regex: "/deprecation/migration-guides/" }
      frontmatter: { componentUsageGuide: { in: [$slug] } }
    ) {
      body
      tableOfContents
    }
    migrateAwayGuide: mdx(
      fileAbsolutePath: { regex: "/deprecation/migration-guides/" }
      frontmatter: { replacing: { in: [$slug] } }
    ) {
      body
    }
    relatedPatterns: allMdx(
      filter: { fileAbsolutePath: { regex: "/patterns/" }, frontmatter: { relatedContentCards: { in: [$slug] } } }
    ) {
      nodes {
        frontmatter {
          subtitle
          title
        }
        slug
      }
    }
    relatedHooks: allMdx(
      filter: { fileAbsolutePath: { regex: "/hooks/" }, frontmatter: { relatedContentCards: { in: [$slug] } } }
    ) {
      nodes {
        frontmatter {
          subtitle
          title
        }
        slug
      }
    }
    relatedComponentGuides: allMdx(
      filter: { fileAbsolutePath: { regex: "/usage-guides/" }, frontmatter: { relatedContentCards: { in: [$slug] } } }
    ) {
      nodes {
        frontmatter {
          subtitle
          title
          componentUsageGuide
        }
        slug
      }
    }
    relatedComponents: allComponentsJson(
      filter: { displayName: { ne: $slug }, group: { eq: $group } }
      sort: { fields: displayName, order: ASC }
    ) {
      nodes {
        displayName
        purpose
        samples {
          name
          code
        }
      }
    }
  }
`;
