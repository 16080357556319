import React from 'react';
import * as Ink from '@carta/ink';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { MDRendererWithMapping } from '../../Common/MarkdownRenderers';

const TestingTab = props => {
  const { testingMd } = props.component;

  const noTestingGuide = !testingMd;

  React.useEffect(() => {
    if (typeof window !== 'undefined' && window.location.hash.split('#').length > 2) {
      const hash = window.location.hash.split('#')[2];
      scrollTo(`#${hash}`);
    }
  });

  return (
    <>
      {noTestingGuide && <Ink.EmptyState text="No testing information available" type="page" icon="notfound" />}

      {testingMd && <MDRendererWithMapping md={testingMd} isPlainText />}
    </>
  );
};

export default TestingTab;
