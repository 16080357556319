import React, { useContext, useState } from 'react';
import * as Ink from '@carta/ink';
import { MDRendererWithMapping } from '../../Common/MarkdownRenderers';
import { MdnExternalLinks } from '../../Common/MdnExternalLinks';
import StyledLink from '../../Common/StyledLink';
import TypeScriptType, { CodeWithAllStyles, CodeWithFontStyles, PropNameFontStyles } from '../../Common/PropDisplay';

import DocsContext from '../../../contexts/DocsContext';

const PropTableHeader = () => (
  <Ink.NewTable.Head>
    <Ink.NewTable.Row>
      <Ink.NewTable.HeadCell>Name</Ink.NewTable.HeadCell>
      <Ink.NewTable.HeadCell>Type</Ink.NewTable.HeadCell>
      <Ink.NewTable.HeadCell>Required</Ink.NewTable.HeadCell>
      <Ink.NewTable.HeadCell>Default</Ink.NewTable.HeadCell>
      <Ink.NewTable.HeadCell width="280px">Description</Ink.NewTable.HeadCell>
    </Ink.NewTable.Row>
  </Ink.NewTable.Head>
);

const PropValueDisplay = ({ required, value }: { required: boolean; value?: string }) => {
  if (required && value === 'undefined') {
    return 'N/A';
  }

  return (
    <pre>
      <CodeWithFontStyles>{value}</CodeWithFontStyles>
    </pre>
  );
};

const PropCard = ({ prop }) => (
  <Ink.Box top="gutter" bottom="large">
    <Ink.Box bottom="medium">
      <Ink.HStack spacing="xsmall">
        <PropNameFontStyles>{prop.name}</PropNameFontStyles>
        {prop.required && (
          <Ink.CustomText color="red" weight="600">
            *
          </Ink.CustomText>
        )}
      </Ink.HStack>
      <Ink.Divider />
    </Ink.Box>
    <Ink.VStack spacing="gutter">
      {prop.defaultValue.value !== 'undefined' && (
        <Ink.Box>
          <Ink.Text variant="body-1" trim>
            Default value
          </Ink.Text>
          <PropValueDisplay required={prop.required} value={prop.defaultValue.value} />
        </Ink.Box>
      )}
      <Ink.Box>
        <Ink.Text variant="body-1" trim>
          Type
        </Ink.Text>
        <TypeScriptType prop={prop.type} />
      </Ink.Box>
      <Ink.Box>
        <Ink.Text variant="body-1" trim>
          Description
        </Ink.Text>
        <MDRendererWithMapping md={prop.description} />
      </Ink.Box>
    </Ink.VStack>
  </Ink.Box>
);

const PropRow = ({ prop }) => (
  <Ink.NewTable.Row>
    <Ink.NewTable.Cell>
      <CodeWithAllStyles>{prop.name}</CodeWithAllStyles>
    </Ink.NewTable.Cell>
    <Ink.NewTable.Cell>
      <TypeScriptType prop={prop.type} />
    </Ink.NewTable.Cell>
    <Ink.NewTable.Cell>
      {/* MD rendered needed, even for strings, because resulting markup yields desired vertical alignment */}
      <MDRendererWithMapping md={prop.required ? 'Yes' : 'No'} />
    </Ink.NewTable.Cell>
    <Ink.NewTable.Cell>
      <PropValueDisplay required={prop.required} value={prop.defaultValue.value} />
    </Ink.NewTable.Cell>
    <Ink.NewTable.Cell>
      <MDRendererWithMapping md={prop.description} />
    </Ink.NewTable.Cell>
  </Ink.NewTable.Row>
);

const DeprecatedPropTableHeader = () => (
  <Ink.NewTable.Head>
    <Ink.NewTable.Row>
      <Ink.NewTable.HeadCell width="200px">Name</Ink.NewTable.HeadCell>
      <Ink.NewTable.HeadCell width="250px">Type</Ink.NewTable.HeadCell>
      <Ink.NewTable.HeadCell width="200px">Replacement Prop</Ink.NewTable.HeadCell>
      <Ink.NewTable.HeadCell>Description</Ink.NewTable.HeadCell>
    </Ink.NewTable.Row>
  </Ink.NewTable.Head>
);

const DeprecatedPropRow = ({ prop }) => (
  <Ink.NewTable.Row>
    <Ink.NewTable.Cell>
      <s>{prop.name}</s>
    </Ink.NewTable.Cell>
    <Ink.NewTable.Cell>
      <TypeScriptType prop={prop.type} />
    </Ink.NewTable.Cell>
    <Ink.NewTable.Cell>
      <MDRendererWithMapping
        md={prop.replacementProp ? `\`${prop.replacementProp}\`` : 'There are no replacements for this prop'}
      />
    </Ink.NewTable.Cell>
    <Ink.NewTable.Cell>
      <MDRendererWithMapping md={prop.description} />
    </Ink.NewTable.Cell>
  </Ink.NewTable.Row>
);

const DeprecatedPropCard = ({ prop }) => (
  <Ink.Box top="gutter" bottom="large">
    <Ink.Box bottom="medium">
      <PropNameFontStyles>
        <s>{prop.name}</s>
      </PropNameFontStyles>
      <Ink.Divider />
    </Ink.Box>
    <Ink.VStack spacing="gutter">
      <Ink.Box>
        <Ink.Text variant="body-1" trim>
          Replacement prop
        </Ink.Text>
        <MDRendererWithMapping
          md={prop.replacementProp ? `\`${prop.replacementProp}\`` : 'There are no replacements for this prop'}
        />
      </Ink.Box>
      <Ink.Box>
        <Ink.Text variant="body-1" trim>
          Type
        </Ink.Text>
        <TypeScriptType prop={prop.type} />
      </Ink.Box>
      <Ink.Box>
        <Ink.Text variant="body-1" trim>
          Description
        </Ink.Text>
        <MDRendererWithMapping md={prop.description} />
      </Ink.Box>
    </Ink.VStack>
  </Ink.Box>
);

const PropTable = ({ propData }) => {
  const [openDeprecated, setOpenDeprecated] = useState(false);

  const deprecatedProps: Array<Record<string, any>> = [];
  const validProps: Array<Record<string, any>> = [];

  const filterDeprecatedProps = source =>
    source.forEach(prop => {
      if (prop.deprecatedProp) {
        deprecatedProps.push(prop);
      } else {
        validProps.push(prop);
      }
    });

  filterDeprecatedProps(propData);

  const handleDeprecatedTable = () => setOpenDeprecated(!openDeprecated);

  const { isMobile } = Ink.useWindowWidth();

  return (
    <Ink.VStack>
      {isMobile ? (
        <Ink.VStack>
          {validProps.map(prop => (
            <PropCard key={prop.name} prop={prop} />
          ))}
        </Ink.VStack>
      ) : (
        <Ink.NewTable>
          <PropTableHeader />
          <Ink.NewTable.Body>
            {validProps.map(prop => (
              <PropRow key={prop.name} prop={prop} />
            ))}
          </Ink.NewTable.Body>
        </Ink.NewTable>
      )}
      {deprecatedProps.length > 0 && (
        <>
          {!openDeprecated && (
            <Ink.Box vertical="small">
              <Ink.Button onClick={handleDeprecatedTable}>Show deprecated props</Ink.Button>
            </Ink.Box>
          )}
          {openDeprecated && (
            <Ink.Block>
              <Ink.Heading variant="heading-2">Deprecated Props</Ink.Heading>
              <Ink.Text>
                These props are shown here for backwards compatibility but shouldn't be used. If you feel your use case{' '}
                <em>requires</em> you to use one of these props, reach out to us in{' '}
                <Ink.Anchor href="https://grid-carta.enterprise.slack.com/archives/CBFKQSKTN">#ink</Ink.Anchor> for
                alternatives.
              </Ink.Text>
              {isMobile ? (
                deprecatedProps.map(prop => <DeprecatedPropCard key={prop.name} prop={prop} />)
              ) : (
                <Ink.NewTable>
                  <DeprecatedPropTableHeader />
                  <Ink.NewTable.Body>
                    {deprecatedProps.map(prop => (
                      <DeprecatedPropRow key={prop.name} prop={prop} />
                    ))}
                  </Ink.NewTable.Body>
                </Ink.NewTable>
              )}
            </Ink.Block>
          )}
        </>
      )}
    </Ink.VStack>
  );
};

const filterIgnoredProps = component =>
  component.props.filter(prop => prop.description === null || !prop?.description.includes('@ignore'));

const filterData = (data, value) =>
  data.filter(
    prop =>
      prop.name.toLowerCase().includes(value.toLowerCase()) ||
      prop.description?.toLowerCase().includes(value.toLowerCase()),
  );

const PropsTab = ({ component, relatedComponents, path }) => {
  const sortOptions = [
    {
      id: 'significance',
      value: 'significance',
      label: 'By significance',
    },
    {
      id: 'alphabetical',
      value: 'alphabetical',
      label: 'Alphabetically',
    },
  ];

  const { defaultPropsSort } = useContext(DocsContext);

  const initialData = filterIgnoredProps(component);

  const [data, setData] = useState(initialData);
  const [query, setQuery] = useState('');
  const [sortOption, setSortOption] = useState(defaultPropsSort || 'significance');

  const onFilterChange = ({ target }) => {
    const { value } = target;
    setData(filterData(initialData, value));
    setQuery(value);
  };

  const onSortChange = value => {
    if (value === 'significance') {
      setData(initialData);
    } else {
      setData(data.sort((a, b) => a.name.localeCompare(b.name)));
    }

    setSortOption(value);
  };

  return (
    <Ink.Box top="large">
      <Ink.VStack>
        {/*
          When a user is filtering the props table and their query returns a data.length of 0, we want the filter UI to stay on screen!
          `query === ''` is necessary to hide the filter UI when a component has no ink-defined props. (e.g. VerticalNav)
        */}
        {query === '' && data.length === 0 ? null : (
          <Ink.HStack align="distributed">
            <Ink.NewInput autoFocus placeholder="Filter" value={query} onChange={onFilterChange} />
            <Ink.Dropdown
              trigger={({ onClick }) => (
                <Ink.Dropdown.Trigger onClick={onClick} type="transparent">
                  Sort {sortOption === 'alphabetical' ? 'alphabetically' : `by ${sortOption}`}
                </Ink.Dropdown.Trigger>
              )}
              align="right"
            >
              {sortOptions.map(option => (
                <Ink.Dropdown.Item
                  key={option.id}
                  render={props => (
                    <Ink.Dropdown.Belt>
                      <Ink.Dropdown.Radio
                        {...props}
                        id={option.id}
                        label={option.label}
                        value={option.value}
                        checked={option.value === sortOption}
                        name="Dropdown-radio-group"
                        onClick={() => onSortChange(option.value)}
                      />
                    </Ink.Dropdown.Belt>
                  )}
                />
              ))}
            </Ink.Dropdown>
          </Ink.HStack>
        )}
        {data.length > 0 && (
          <Ink.Box bottom="large">
            <PropTable propData={data} />
          </Ink.Box>
        )}
        {component.HTMLType && (
          <Ink.Box>
            <Ink.Heading variant="heading-2">HTML5</Ink.Heading>
            {component.HTMLType !== 'HTMLElement' ? (
              <Ink.Text>
                This component will spread props from <MdnExternalLinks type={component.HTMLType} /> with the exception
                of <CodeWithAllStyles>className</CodeWithAllStyles> and <CodeWithAllStyles>style</CodeWithAllStyles>.
              </Ink.Text>
            ) : (
              <Ink.Text>
                This component will spread props with the exception of <CodeWithAllStyles>className</CodeWithAllStyles>{' '}
                and <CodeWithAllStyles>style</CodeWithAllStyles>. Check out more HTML documentation at{' '}
                <Ink.Anchor href="https://developer.mozilla.org">MDN</Ink.Anchor>.
              </Ink.Text>
            )}
          </Ink.Box>
        )}
        {relatedComponents?.nodes?.length > 0 && (
          <Ink.Box bottom="large">
            <Ink.Heading variant="heading-2">Related Components</Ink.Heading>
            <Ink.VStack spacing="medium">
              {relatedComponents.nodes.map(cmp => (
                <StyledLink to={`/components/${cmp.displayName}`} title={cmp.displayName} key={cmp.displayName}>
                  {cmp.displayName}
                </StyledLink>
              ))}
            </Ink.VStack>
          </Ink.Box>
        )}
      </Ink.VStack>
    </Ink.Box>
  );
};

export default PropsTab;
